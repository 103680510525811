import Router from './routes';
import './Global.scss';

function App() {

  return (
    <Router />
  );
}

export default App;
